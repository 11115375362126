<template>
    <div class="page">
        <div class="nav">
            <div class="nav-item active">展会概况</div>
            <div class="nav-item" @click="btnJump(surveySite)">展览展示</div>
            <div class="nav-item" @click="btnJump(cloudSite)">企业名录</div>
            <div class="nav-item" @click="btnJump(contactSite)">联系方式</div>
            <div class="nav-item" @click="btnJump(site)">返回官网</div>
        </div>

        <div class="main">
            <img
                class="logo-2 backInUp animation-delay-1"
                src="@/assets/images/home/logo-2.png"
            />
            <img
                class="zh backInUp animation-delay-2"
                src="@/assets/images/home/zh.png"
            />
            <img
                class="en backInUp animation-delay-3"
                src="@/assets/images/home/en.png"
            />
            <div class="border widthIn animation-delay-5">
                <img src="@/assets/images/home/border.png" />
            </div>

            <div class="btn-group backInUp animation-delay-4">
                <img
                    @click="btnJump('/second')"
                    class="btn-hover"
                    src="@/assets/images/home/btn-1.png"
                />
                <img
                    @click="btnJump(yxgUrl)"
                    class="btn-hover"
                    src="@/assets/images/home/btn-2.png"
                />
                <img
                    @click="btnJump(gzzqUrl)"
                    class="btn-hover"
                    src="@/assets/images/home/btn-3.png"
                />
            </div>
        </div>

        <img class="footer" src="@/assets/images/home/m/footer.png" />
    </div>
</template>
<script>
import { jump } from "@/utils/utils.js";
import { cacheImage } from "@/utils/utils.js";
export default {
    data() {
        return {
            gzzqUrl: "https://ceshi.vrnewg.com/gzzq",
            yxgUrl: "https://ceshi.vrnewg.com/yxg/",
            site: "https://www.cimee.com.cn/index.aspx",
            surveySite: "https://www.cimee.com.cn/survey.aspx",
            cloudSite: "https://www.cimee.com.cn/cloud.aspx?type=150",
            contactSite: "https://www.cimee.com.cn/contact.aspx",
        };
    },
    mounted() {
        cacheImage(require("@/assets/images/other/m/bg.png"));
    },
    methods: {
        btnJump(path, target = "_self") {
            jump(path, target);
        },
    },
};
</script>
<style lang="less" scoped>
.page {
    width: 100%;
    height: 100%;
    font-size: 32px;
    background-image: url("~@/assets/images/home/m/bg-1.png");
    background-size: 100% 100%;
    overflow: hidden;
}

.nav {
    background-color: #012f6d;
    height: 100px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    color: #fff;

    &-item {
        &.active {
            color: #46c0ff;
        }
    }
}

.main {
    margin-top: 172px;

    .logo-2 {
        width: 271px;
        margin-left: 30px;
    }

    .zh {
        margin-top: 98px;
        margin-left: 27px;
        width: 648px;
    }

    .en {
        margin-top: 30px;
        margin-left: 27px;
        width: 428px;
    }

    .border {
        margin-top: 28px;
        margin-left: 30px;
        overflow: hidden;

        img {
            width: 100px;
        }
    }

    .btn-group {
        margin-top: 93px;
        margin-left: 27px;

        img {
            width: 202px;
            height: 63px;
            margin-right: 4px;
        }
    }
}

.footer {
    position: fixed;
    left: 20px;
    bottom: 57px;
    width: 709px;
}
</style>
