<template>
    <div class="container">
        <div class="main" ref="main" :style="mainStyle">
            <div class="logo-2 backInUp animation-delay-1">
                <img src="@/assets/images/home/logo-2.png" />
            </div>

            <div class="zh backInUp animation-delay-2">
                <img src="@/assets/images/home/zh.png" />
            </div>

            <div class="en backInUp animation-delay-3">
                <img src="@/assets/images/home/en.png" />
            </div>

            <div class="border widthIn animation-delay-5">
                <img src="@/assets/images/home/border.png" />
            </div>

            <div class="btn-group backInUp animation-delay-4">
                <img
                    class="btn btn-hover"
                    @click="btnJump('/second')"
                    src="@/assets/images/home/btn-1.png"
                />
                <img
                    class="btn btn-hover"
                    @click="btnJump(yxgUrl)"
                    src="@/assets/images/home/btn-2.png"
                />
                <img
                    class="btn btn-hover"
                    @click="btnJump(gzzqUrl)"
                    src="@/assets/images/home/btn-3.png"
                />
            </div>
        </div>

        <div class="footer">
            <img src="@/assets/images/home/footer.png" />
        </div>
    </div>
</template>

<script>
import { jump } from "@/utils/utils.js";
import { cacheImage } from "@/utils/utils.js";
export default {
    name: "Home",
    data() {
        return {
            gzzqUrl: "https://ceshi.vrnewg.com/gzzq",
            yxgUrl: "https://ceshi.vrnewg.com/yxg/",
            mainStyle: {},
        };
    },
    mounted() {
        cacheImage(require("@/assets/images/other/bg.png"));
        this.onReize();
    },
    methods: {
        btnJump(path) {
            jump(path);
        },
        onReize() {
            const mainRef = this.$refs.main;
            const windowHeight = window.innerHeight;
            const mainHeight = mainRef.clientHeight;
            this.mainStyle = {
                transform: `scale(${535 / 1080 / (mainHeight / windowHeight)})`,
                transformOrigin: "0 0",
            };
        },
    },
};
</script>

<style scoped lang="less">
.container {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/images/home/bg-1.png");
    background-size: 100% 100%;
}

.main {
    position: absolute;
    top: 19%;
    left: 343px;

    .logo-2 {
        img {
            width: 271px;
        }
    }

    .zh {
        margin-top: 98px;

        img {
            width: 708px;
        }
    }

    .en {
        margin-top: 45px;

        img {
            width: 483px;
        }
    }

    .border {
        margin-top: 53px;

        img {
            width: 100px;
        }
    }

    .btn-group {
        display: flex;
        margin-top: 91px;

        .btn {
            width: 202px;
            margin-right: 38px;
            cursor: pointer;
        }
    }
}

.footer {
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);

    img {
        width: 621px;
        height: 54px;
    }
}
</style>
